import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import { useNavigate } from 'react-router-dom';
import './Setting.css';

const SupabaseSettingsForm = () => {
  const [api, setApiKey] = useState('');
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${Helpers.apiUrl}admin/getSupabase`, Helpers.authHeaders);
        setApiKey(response.data['api'] || '');
        setUrl(response.data['url'] || '');
      } catch (error) {
        console.error('Failed to fetch settings', error);
      }
      setIsLoading(false);
    };

    fetchSettings();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${Helpers.apiUrl}admin/updateSupabase`, { api, url }, Helpers.authHeaders);
      Helpers.toast("success", "Data Updated Successfully");
      console.log(api, url)
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Failed to save settings', error);
      Helpers.toast('error', 'Failed to save settings. Please check your API Key and URL.');
    }

    setIsLoading(false);
  };

  return (
    <div className="container mt-5 p-5 shadow-lg rounded bg-white custom-width">
      <div className="row justify-content-center">
        <div className="col-md-6 shadow-lg py-5 rounded-5">
          <form onSubmit={handleSubmit} className="p-3">
            <h2 className="mb-4 text-center">Supabase Settings</h2>
            <div className="mb-3">
              <label htmlFor="api" className="form-label">Supabase API Key:</label>
              <input
                type="text"
                className="form-control"
                id="api"
                value={api}
                onChange={(event) => setApiKey(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="url" className="form-label">Supabase URL:</label>
              <input
                type="text"
                className="form-control"
                id="url"
                value={url}
                onChange={(event) => setUrl(event.target.value)}
              />
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupabaseSettingsForm;
