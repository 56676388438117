import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import useTitle from "../../../Hooks/useTitle";
import TextInput from "../../../Components/Input";
import SearchHeader from "../../../Components/SearchHeader";
import PageLoader from "../../../Components/Loader/PageLoader";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { usePlan } from "../../../Context/PlanContext";

const AdminUsers = () => {
    useTitle("Users");

    const defaultUser = {
        name: "",
        email: "",
        password: "",
        is_active: false,
        is_subadmin: false, // New field for subadmin selection
    };

    const [users, setUsers] = useState([]);
    const [orgData, setOrgData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(null);  // New state for confirmation

    const [isDeleting, setIsDeleting] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [showUser, setShowUser] = useState(true);
    const [newUser, setNewUser] = useState(defaultUser);
    const [errors, setErrors] = useState({});
    const { userData } = usePlan();
    const getUsers = () => {
        axios.get(`${Helpers.apiUrl}user/all`, Helpers.authHeaders).then(response => {
            setUsers(response.data);
            setOrgData(response.data);
        });
    }
    console.log("New User", newUser)
    const handleDelete = (userId) => {
        if (confirmDelete === userId) {
            setIsDeleting(true);
            axios.post(`${Helpers.apiUrl}user/Delete/${userId}`, null, Helpers.authHeaders)
                .then(response => {
                    Helpers.toast("success", response.data.message);
                    getUsers();
                    setConfirmDelete(null);  // Reset confirmation
                    setSelectedUser(0);
                    setIsDeleting(false);
                })
                .catch(error => {
                    Helpers.toast("error", error.response.data.error);
                    setIsDeleting(false);
                });
        } else {
            setConfirmDelete(userId);  // Set user ID for confirmation
        }
    }


    // const handleDelete = (userId) => {
    //     setIsDeleting(true);
    //     axios.post(`${Helpers.apiUrl}user/Delete/${userId}`, null, Helpers.authHeaders)
    //         .then(response => {
    //             Helpers.toast("success", response.data.message);
    //             getUsers();
    //             setSelectedUser(0);
    //             setIsDeleting(false);
    //         })
    //         .catch(error => {
    //             Helpers.toast("error", error.response.data.error);
    //             setIsDeleting(false);
    //         });
    // }

    const handleCancel = () => {
        setShowAddUser(false);
        setShowUser(true);
        setNewUser(defaultUser);
    }

    const saveUser = () => {
        console.log(newUser)
        setIsLoading(true);
        // setErrors({});
        axios.post(`${Helpers.apiUrl}user/create`, newUser, Helpers.authHeaders)
            .then(response => {
                Helpers.toast("success", response.data.message);
                setNewUser(defaultUser);
                getUsers();
                setShowAddUser(false);
                setShowUser(true);
                setIsLoading(false);
            })
            .catch(error => {
                Helpers.toast("error", error.response.data.message);
                setErrors(error.response.data.errors || {});
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getUsers();
    }, []);

    if (!newUser) {
        return <PageLoader />
    }

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Users List</h2>
                                    <p>Registered users on HumGPT</p>
                                </div>
                                <div>
                                    {!showAddUser && <button className="btn btn-primary" onClick={() => {
                                        setShowAddUser(true);
                                        setShowUser(false);
                                    }}><em className="icon ni ni-plus"></em> Add New User</button>}
                                </div>
                            </div>
                        </div>
                        {(showUser) && <div className="nk-block">
                            <SearchHeader title={"Users List"} orgData={orgData} setData={setUsers} columns={['name']} />
                            <div className="card shadow-none">
                                <div className="card-body">
                                    <div className="row g-3 gx-gs">
                                        <div className="col-md-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. #</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Joined On</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users.length === 0 && <tr>
                                                        <td colSpan={3}>No records found...</td>
                                                    </tr>}
                                                    {users.map((user, index) => (
                                                        <tr key={user.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{user.name}</td>
                                                            <td>{user.email}</td>
                                                            <td><Moment date={user.created_at} format="MMM Do YYYY" /></td>
                                                            <td>
                                                                {confirmDelete === user.id ? (
                                                                    <div className="d-inline">
                                                                        <button onClick={() => handleDelete(user.id)} disabled={isDeleting} className="btn btn-outline-danger btn-sm ml10">
                                                                            <em className="icon ni ni-check"></em><span className="ml5">{isDeleting ? 'Deleting...' : 'Yes, Delete'}</span>
                                                                        </button>
                                                                        <button onClick={() => setConfirmDelete(null)} className="btn btn-outline-secondary btn-sm ms-2">
                                                                            <em className="icon ni ni-cross"></em><span className="ml5">Cancel</span>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-inline">
                                                                        <Link to={`/admin/chats/user/${user.id}`} className="btn btn-outline-primary btn-sm ml10">
                                                                            <em className="icon ni ni-eye"></em><span className="ml5">Chats</span>
                                                                        </Link>
                                                                        <Link to={`/admin/user/edit-user/${user.id}`} className="btn btn-outline-success btn-sm ms-2">
                                                                            <em className="icon ni ni-edit"></em><span className="ml5">Edit</span>
                                                                        </Link>
                                                                        {userData.userRole == "subadmin" ? null : (
                                                                            <button onClick={() => setConfirmDelete(user.id)} className="btn btn-outline-danger btn-sm ml10">
                                                                                <em className="icon ni ni-trash"></em><span className="ml5">Delete</span>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {(showAddUser) && <div className="nk-block">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-head-content"><h3 className="nk-block-title">Add New User</h3></div>
                            </div>
                            <div className="card shadow-none">
                                <div className="card-body">
                                    <div className="row g-3 gx-gs">
                                        <div className="col-md-12 form-group">
                                            <label className="form-label">Name</label>
                                            <TextInput
                                                name="name"
                                                value={newUser.name}
                                                onChange={(e) => setNewUser(prev => ({ ...prev, name: e.target.value }))}
                                                error={errors.name}
                                                placeholder="Enter user's name"
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label className="form-label">Email</label>
                                            <TextInput
                                                type="email"
                                                name="email"
                                                value={newUser.email}
                                                onChange={(e) => setNewUser(prev => ({ ...prev, email: e.target.value }))}
                                                error={errors.email}
                                                placeholder="Enter user's email"
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">

                                            <label className="form-label">Password</label>
                                            <TextInput
                                                type="password"
                                                name="password"
                                                value={newUser.password}
                                                onChange={(e) => setNewUser(prev => ({ ...prev, password: e.target.value }))}
                                                error={errors.password}
                                                placeholder="Enter user's password"
                                            />
                                        </div>
                                        <div className="col-md-6 d-flex gap-2 form-group">
                                            <div className="d-flex gap-2">
                                                <label>Active</label>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="is_active"
                                                        checked={newUser.is_active}
                                                        onChange={(e) => setNewUser(prev => ({ ...prev, is_active: e.target.checked }))}
                                                    />
                                                </div>
                                            </div>
                                            {userData.userRole == "subadmin" ? null : (
                                                <div className="d-flex gap-2">
                                                    <label>Subadmin</label>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="is_subadmin"
                                                            checked={newUser.is_subadmin}
                                                            onChange={(e) => setNewUser(prev => ({ ...prev, is_subadmin: e.target.checked }))}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-primary" disabled={isLoading} onClick={saveUser}>{isLoading ? 'Saving...' : 'Save User'}</button>
                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminUsers;
